import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {Translate, withLocalize} from "react-localize-redux";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {E_Currency, E_UserRoles, T_APP_ICONS} from "../../../models/constants/Constants_Shared";
import {T_SharedReduxPropTypes} from "../../../models/Models_Shared";
import {E_UserPermissionOption, T_AnonymousPermissions, T_UserModel, T_UserPermissions} from "../../../models/Models_User";
import routes from "../../../routes/routes";
import {Action_User_Clear, Action_User_Edit_Cancel, Action_User_Edit_Init, Action_User_Edit_Modify, E_UserActions} from "../../../store/actions/users/usersActions";
import {E_Modification} from "../../../store/Constants_StoreShared";
import {Selector_User_Data, Selector_User_Edit, Selector_User_Root} from "../../../store/selectors/Selectors_Users";
import {Thunk_Language_Fetch_All} from "../../../store/thunk/Thunk_Language";
import {Thunk_User_Fetch, Thunk_User_Remove, Thunk_User_Save} from "../../../store/thunk/Thunk_Users";
import {EO} from "../../../utils/extensions";
import is from "../../../utils/is";
import {formatAsPrice, get, parseBool, resolveItemName} from "../../../utils/utils";
import ButtonsConstructor from "../../shared/ButtonsConstructor";
import Dropdown from "../../shared/Input/Dropdown";
import NumberInput from "../../shared/Input/NumberInput";
import TextInput from "../../shared/Input/TextInput";
import ToggleButton from "../../shared/Input/ToggleButton";
import Fa from "../../tools/Icons/Fa";
import "../../../styles/pages/User/UserDetail.scss";
import Toolbar from "../../shared/Toolbar";
import DetailWrapper from "../../shared/DetailWrapper";
import DropdownLanguage from "../../shared/Input/Dropdowns/DropdownLanguage";
import ChangePassword from "./Password/ChangePassword";
import {Selector_Auth_Principal} from "../../../store/selectors/Selectors_Auth";
import {Thunk_Profile_Fetch, Thunk_Profile_Remove, Thunk_Profile_Save} from "../../../store/thunk/Thunk_Profile";
import Restricted from "../../shared/Restrictions/Restricted";
import PartnerSelectInput from "../Partners/PartnerSelectInput";
import {T_RestrictionPresets} from "../../../models/Models_Restrictions";
import {comparePermissionLevels} from "../../../utils/restrictionUtils";
import ItemRedirect from "../../shared/ItemRedirect";
import {service_sendEmailVerification} from "../../../services/Service_Users";
import {errorHandler} from "../../../store/ResponseHandling";
import {E_ToastStyle} from "../../../models/Models_Toast";
import CubeSpinner from "../../shared/LoadingIndicator/CubeSpinner";
import {PIN_MIN_LENGTH, PinInput} from "../../shared/Input/PinInput";

class UserDetail extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			sending: false,
		};
	}

	componentDidMount() {
		const {userID, Thunk_User_Fetch, Action_User_Edit_Init, principal, Thunk_Profile_Fetch} = this.props;

		if (userID) {
			if(userID == principal.id) {
				Thunk_Profile_Fetch();
			} else {
				Thunk_User_Fetch(userID);
			}
		} else {
			Action_User_Edit_Init(T_UserModel);
		}
	}

	componentWillUnmount() {
		const {Action_User_Clear} = this.props;

		Action_User_Clear();
	}

	_renderToolbar() {
		const {edit, user, Action_User_Edit_Cancel, Thunk_User_Remove, userID, fetching, failed, Action_User_Edit_Init, Thunk_User_Save, principal, Thunk_Profile_Save, Thunk_Profile_Remove} = this.props;
		const isEdit = is.valid(edit);
		let passwordMatch = is.equal(edit.password, edit.passwordRepeat)

		return <Toolbar
			section={"users"}
			applyRestrictions={principal.id != userID}
			isEdit={isEdit}
			backUrl={routes.USERS}
			content={<h1><Translate id={userID ? (isEdit ? "user_edit" : "user_detail") : "user_create"}/></h1>}
			onEditCancel={() => Action_User_Edit_Cancel()}
			onEdit={() => Action_User_Edit_Init()}
			onSave={() => principal.id == userID ? Thunk_Profile_Save() : Thunk_User_Save()}
			onRemove={() => principal.id == userID ? Thunk_Profile_Remove() : Thunk_User_Remove(userID)}
			disableActionButtons={fetching || failed}
			disableSave={userID == 0 && !passwordMatch || (edit.defaultPin?.length > 0 && edit.defaultPin?.length < PIN_MIN_LENGTH)}
			disabledSaveTippy={this._getDisabledSaveTippyContent(passwordMatch)}
			itemID={userID}
			actionButtons={actionButtons => [
				userID == principal.id && {
					icon: "key",
					tippy: <Translate id={"password_change"}/>,
					action: () => this._openChangePassword()
				},
				(userID == principal.id && !user.emailVerified) && {
					icon: "envelope",
					tippy: <Translate id={"user_resendEmailVerification"}/>,
					action: () => this._resendEmailVerification(),
					confirm: <span><Fa icon={"envelope"} /> <Translate id={"user_resendEmailVerification"} /></span>,
				},
				...actionButtons
			]}
		/>;
	}

	_renderUserData(user) {
		const {Action_User_Edit_Modify, userID, edit} = this.props;
		const isEdit = is.valid(edit);

		return (
			<Fragment>
				<div className={"separator"}><label><Fa icon={"info-circle"} /><Translate id={"user_userData"} /></label></div>
				<div className={"user-data"}>
					<div className={"grid-table grid-icon-auto-1fr"}>
						<Restricted role={E_UserRoles.ADMIN}>
							<Fa icon={"power-off"} />
							<label><Translate id={"state_ENABLED"} /></label>
							{
								this._isEditSwitch(
									<span><Translate id={user.enabled ? "yes" : "no"} /></span>,
									<ToggleButton
										defaultValue={user.enabled}
										onModify={Action_User_Edit_Modify}
										path={`enabled`}
									/>
								)
							}
						</Restricted>
						<span/>
						<label><Translate id={"user_emailVerified"} /></label>
						{
							this._isEditSwitch(
								<span><Translate id={user.emailVerified ? "yes" : "no"} /></span>,
								<ToggleButton
									defaultValue={user.emailVerified}
									onModify={Action_User_Edit_Modify}
									path={`emailVerified`}
								/>
							)
						}

						<span />
						<label><Translate id={"user_forename"} /></label>
						{
							this._isEditSwitch(
								<span>{user.forename}</span>,
								<TextInput
									defaultValue={user.forename}
									onModify={Action_User_Edit_Modify}
									path={`forename`}
								/>
							)
						}

						<span />
						<label><Translate id={"user_surname"} /></label>
						{
							this._isEditSwitch(
								<span>{user.surname}</span>,
								<TextInput
									defaultValue={user.surname}
									onModify={Action_User_Edit_Modify}
									path={`surname`}
								/>
							)
						}

						<Fa icon={"at"} />
						<label><Translate id={"email"} /></label>
						{
							this._isEditSwitch(
								<span>
									{
										user.email &&
										<a className={"user-selectable"} href={`mailto:${user.email}`}>{user.email} <Fa icon={"envelope"}/></a>
									}
								</span>,
								<TextInput
									type={"email"}
									defaultValue={user.email}
									onModify={Action_User_Edit_Modify}
									path={`email`}
								/>
							)
						}

						<Fa icon={"phone-alt"} />
						<label><Translate id={"phoneNumber"} /></label>
						{
							this._isEditSwitch(
								<span>
									{
										user.phone &&
										<a className={"user-selectable"} href={`tel:${user.phone}`}>{user.phone} <Fa icon={"phone-volume"}/></a>
										||
										<Translate id={"value_notProvided"}/>
									}
								</span>,
								<TextInput
									type={"tel"}
									defaultValue={user.phone}
									onModify={Action_User_Edit_Modify}
									path={`phone`}
								/>
							)
						}

						{
							userID == 0 &&
							<Fragment>
								<Fa icon={"lock"} />
								<label><Translate id={"password"} /></label>
								<TextInput
									type={"password"}
									defaultValue={user.password}
									onModify={Action_User_Edit_Modify}
									path={`password`}
								/>

								<Fa icon={"lock"} />
								<label><Translate id={"password_repeat"} /></label>
								<TextInput
									type={"password"}
									defaultValue={user.passwordRepeat}
									onModify={Action_User_Edit_Modify}
									path={`passwordRepeat`}
								/>
							</Fragment>
						}

						<Restricted
							role={E_UserRoles.ADMIN}
							applyRestrictions={isEdit}
						>
							<Fa icon={"user-cog"} />
							<label><Translate id={"user_role"} /></label>
							{
								this._isEditSwitch(
									<span><Translate id={`user_role_${get(user, "permissions.role", E_UserRoles.ANONYMOUS)}`} /></span>,
									<Dropdown
										defaultValue={get(user, `permissions.role`)}
										onModify={Action_User_Edit_Modify}
										path={`permissions.role`}
										options={EO(E_UserRoles).toArray(true)}
										onLabel={item => window.translator.translate(`user_role_${item}`)}
									/>
								)
							}
						</Restricted>

						<Restricted
							noPartner={true}
							restricted={T_RestrictionPresets.PARTNERS}
							applyRestrictions={isEdit}
						>
							<Fa icon={T_APP_ICONS.PARTNERS} />
							<label><Translate id={"partner"} /></label>
							{
								this._isEditSwitch(
									<span>{
										user.partner &&
										<ItemRedirect
											href={`${routes.PARTNERS}/${user.partner.id}`}
											wrapChildren={true}
											restricted={T_RestrictionPresets.PARTNERS}
										>
											{resolveItemName(user.partner)}
										</ItemRedirect>
										||
										<Translate id={"value_notProvided"}/>
									}</span>,
									<PartnerSelectInput
										path={`partner`}
										canInvalidate={true}
										onModify={(type, path, value) => {
											Action_User_Edit_Modify(type, path, value);
											Action_User_Edit_Modify(type, `permissions.partnerID`, value ? value.id : null);
										}}
										defaultValue={user.partner}
									/>
								)
							}
						</Restricted>

						<Fa icon={"comment-dollar"} />
						<label><Translate id={"user_agreedToMarketing"} /></label>
						{
							this._isEditSwitch(
								<span><Translate id={user.agreedToMarketing ? "yes" : "no"} /></span>,
								<ToggleButton
									defaultValue={user.agreedToMarketing}
									onModify={Action_User_Edit_Modify}
									path={`agreedToMarketing`}
								/>
							)
						}

						<Fa icon={"language"} />
						<label><Translate id={"locale"} /></label>
						{
							this._isEditSwitch(
								<span>{<Translate id={user.locale ? `locale_${user.locale}` : "value_notProvided"} />}</span>,
								<DropdownLanguage
									defaultValue={user.locale}
									onModify={Action_User_Edit_Modify}
									path={`locale`}
								/>
							)
						}

						<Restricted
							role={E_UserRoles.ADMIN}
						>
							{
								this._isEditSwitch(
									user.defaultPin && <>
										<Fa icon={"key"} />
										<label><Translate id={"user_defaultPin"} /></label>
										<span>{user.defaultPin}</span>
									</>,
									<>
										<Fa icon={"key"} style={{marginTop: "-1.5em"}} />
										<label style={{marginTop: "-1.5em"}}><Translate id={"user_defaultPin"} /></label>
										<PinInput
											defaultValue={user.defaultPin}
											onModify={Action_User_Edit_Modify}
											path={`defaultPin`}
										/>
									</>
								)
							}
						</Restricted>
					</div>
				</div>
			</Fragment>
		)
	}

	_renderUserCredit(user) {
		const {Action_User_Edit_Modify, edit, activeLanguage} = this.props;
		const isEdit = is.valid(edit);

		if(!isEdit && get(user, `creditBalance.length`, 0) == 0) {
			return null;
		}

		return (
			<div className={"user-credit"} style={{minHeight: "400px"}}>
				<div className={"separator"}>
					<label><Fa icon={"wallet"} /><Translate id={"user_credit"} /></label>
					<div>
						{
							isEdit &&
							get(user, `creditBalance.length`, 0) < Object.keys(E_Currency).length &&
							<button className={"grid-button"} onClick={() => {
								Action_User_Edit_Modify(E_Modification.ARRAY_PUSH, `creditBalance`, {key: null, value: 0});
							}}>
								<Fa icon={T_APP_ICONS.ADD} />
								<Translate id={"add"} />
							</button>
						}
					</div>
				</div>
				<div className={"content"}>
					<div className={"grid-table credit-table"}>
						{
							(user.creditBalance || []).map(
								(item, i) => (
									<Fragment key={i}>
										{
											this._isEditSwitch(
												<Fragment>
													<label>{item.key}</label>
													<span>{
														item.key &&
														formatAsPrice(item.value, item.key, activeLanguage.code)
													}</span>
													<span />
												</Fragment>,
												<Fragment>
													<Dropdown
														onModify={Action_User_Edit_Modify}
														path={`creditBalance.${i}.key`}
														defaultValue={item.key}
														options={EO(E_Currency).toArray(true)}
													/>
													<NumberInput
														onModify={Action_User_Edit_Modify}
														defaultValue={item.value}
														path={`creditBalance.${i}.value`}
														wrapInput={true}
														step={0.01}
														suffix={
															item.key &&
															<Translate id={`currency_${item.key}`}/>
														}
													/>
													<ButtonsConstructor
														buttons={[{
															text: "remove",
															icon: T_APP_ICONS.CLOSE,
															action: () => Action_User_Edit_Modify(E_Modification.ARRAY_SPLICE, `creditBalance`, [i, 1]),
															className: "remove-button"
														}]}
													/>
												</Fragment>
											)
										}
									</Fragment>
								)
							)
						}
					</div>
				</div>
			</div>
		)
	}

	_renderPermissions(user) {
		return this._isEditSwitch(
			null,
			<Restricted role={E_UserRoles.ADMIN}>
				<div className={`user-permissions`}>
					<div className={"separator"}>
						<label><Fa icon={"key"} /><Translate id={"user_permissions"} /></label>
					</div>
					<div className={`grid-table grid-icon-auto-1fr`}>
						{
							EO(T_UserPermissions).toArray().filter(i => !["partnerID", "role"].includes(i.key)).map((permission, i) => (
								<Restricted
									key={i}
									applyRestrictions={typeof T_AnonymousPermissions[permission.key] == "string"}
									permissions={{[permission.key]: E_UserPermissionOption.READ}}
								>
									{({principalPermissions}) => !!principalPermissions[permission.key] && (
										<Fragment>
											{this._getIconForPermission(permission)}
											<label><Translate id={permission.key}/></label>
											{this._getControlsForPermission(user, permission, principalPermissions)}
										</Fragment>
									)}
								</Restricted>
							))
						}
					</div>
				</div>
			</Restricted>
		)
	}

	_renderUser(user) {
		const {sending} = this.state;
		const isEdit = is.valid(this.props.edit);
		return (
			<Fragment>
				{this._renderToolbar()}
				<div className={"user"}>
					{this._renderUserData(user)}
					{this._renderPermissions(user)}
					<Restricted
						role={E_UserRoles.ADMIN}
						applyRestrictions={isEdit}
					>
						{this._renderUserCredit(user)}
					</Restricted>
				</div>
				{sending && <CubeSpinner/>}
			</Fragment>
		)
	}

	render() {
		const {user, edit, userID} = this.props;

		return (
			<DetailWrapper
				itemID={userID}
				dataID={(user || {}).id}
				className={"user-detail"}
				isEdit={is.valid(edit)}
				listUrl={routes.USERS}
				errorContent={"user_fetch_failed"}
				selector={Selector_User_Root}
			>
				{this._renderUser((is.valid(edit) ? edit : user) || T_UserModel)}
			</DetailWrapper>
		);
	}

	_isEditSwitch(normal, edit) {
		return is.valid(this.props.edit) ? edit : normal;
	}

	_getIconForPermission(permission) {
		let {key} = permission;

		switch (key) {
			case "lockControl":
				return <Fa icon={"lock"} />;
			case "globalConfiguration":
				return <Fa icon={"cog"} />;
			default:
				return T_APP_ICONS[`${key}`.toUpperCase()] ? <Fa icon={T_APP_ICONS[`${key}`.toUpperCase()]} /> : <span/>;
		}
	}

	_getControlsForPermission(user, permission, principalPermissions = {}) {
		const {Action_User_Edit_Modify} = this.props;
		const {key} = permission;


		switch (key) {
			case "lockControl":
				return (
					<ButtonsConstructor wrapInNav={true} buttons={["ENABLED", "DISABLED"].map(option => ({
						text: <Translate id={`state_${option}`}/>,
						action: () => Action_User_Edit_Modify(E_Modification.ITEM_SET, `permissions.${key}`, parseBool(option)),
						active: parseBool(option) === parseBool(get(user, `permissions.${key}`))
					}))} />
				);
			default:
				return (
					<ButtonsConstructor wrapInNav={true} buttons={EO(E_UserPermissionOption).toArray(true).map(option => ({
						text: <Translate id={`permission_${option}`}/>,
						action: () => Action_User_Edit_Modify(E_Modification.ITEM_SET, `permissions.${key}`, option),
						active: option === get(user, `permissions.${key}`),
						hidden: !comparePermissionLevels(principalPermissions[key], option),
					}))} />
				)
		}
	}

	_openChangePassword() {
		window.modal.open({
			body: <ChangePassword/>,
			header: <label><Translate id={"password_change"}/></label>
		});
	}

	_resendEmailVerification() {
		this.setState({sending: true});
		service_sendEmailVerification().then(() => {
			window.toaster.showToast({
				style: E_ToastStyle.SUCCESS,
				content: "success_" + E_UserActions.PROFILE_SEND_EMAIL_VERIFICATION,
			});
		}, (error) => {
			errorHandler(error, E_UserActions.PROFILE_SEND_EMAIL_VERIFICATION);
		}).finally(() => {
			this.setState({sending: false});
		});
	}

	_getDisabledSaveTippyContent(passwordMatch) {
		const {userID, edit} = this.props;

		if(userID == 0 && !passwordMatch) {
			return <div style={{padding: 5}}><Translate id={"passwords_mismatch"} /></div>
		}

		if(edit.defaultPin?.length > 0 && edit.defaultPin?.length < PIN_MIN_LENGTH) {
			return <div style={{padding: 5}}><Translate id={"pinValidationError_minLength"}/></div>
		}
	}
}

UserDetail.propTypes = {
	...T_SharedReduxPropTypes,

	user: PropTypes.object.isRequired,
	userID: PropTypes.number.isRequired,

	principal: PropTypes.object,

	Thunk_User_Fetch: PropTypes.func.isRequired,
	Action_User_Edit_Modify: PropTypes.func.isRequired,
	Action_User_Edit_Init: PropTypes.func.isRequired,
	Action_User_Edit_Cancel: PropTypes.func.isRequired,
	Thunk_Language_Fetch_All: PropTypes.func.isRequired,
	Thunk_User_Save: PropTypes.func.isRequired,
	Thunk_User_Remove: PropTypes.func.isRequired,
};

UserDetail.stateTypes = {
	sending: PropTypes.bool
};

UserDetail.defaultProps = {
	user: T_UserModel
};

const mapStateToProps = state => {
	const {fetching, fetched, failed} = Selector_User_Root(state);
	const user = Selector_User_Data(state);
	const edit = Selector_User_Edit(state);
	const principal = Selector_Auth_Principal(state);

	return {fetching, fetched, failed, user, edit, principal};
};

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		Action_User_Edit_Init,
		Action_User_Edit_Modify,
		Thunk_User_Fetch,
		Action_User_Edit_Cancel,
		Thunk_Language_Fetch_All,
		Thunk_User_Save,
		Thunk_User_Remove,
		Thunk_Profile_Fetch,
		Thunk_Profile_Save,
		Thunk_Profile_Remove,
		Action_User_Clear,
	}, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(UserDetail));
