import is from "../../utils/is";
import { API_URL } from "./constants/endpoints";
import { getJSONHeaders } from "./headers";

export const GET = "GET";
export const PUT = "PUT";
export const POST = "POST";
export const DELETE = "DELETE";

/**
 * Send Request
 * ---
 * Sends a XHR request
 * ```
 *  methods = [GET, POST, PUT, DELETE]
 *
 *  // Possible forms of the request
 *  sendRequest("/something/something") => {url: "/something/something"}
 *  sendRequest(["/something/something", DELETE, body]) => {url: "/something/something", method: DELETE, body: body}
 *  sendRequest({...configuration}) => {...configuration}
 *
 *  // Configuration Options
 *  {
 *      apiUrl: {String} Target API url [Default: {API_URL}]
 *      url: {String} Url [Default: {null}]
 *      method: {String} Method [Default: GET]
 *      headers: {Function} Headers function
 *      body: {String} Body
 *  }
 * ```
 * @param {String|Array|Object}configuration
 * @param {Boolean} forceError Force error **DEBUG**
 * @return {Promise}
 *
 * @see headers.js
 */
export const sendRequest = (configuration, forceError = false) => {
	if (forceError) {
		return new Promise((_, reject) => {
			reject({
				ok: false,
				redirected: false,
				status: 412,
				statusText: "Simulated error",
				type: "simError"
			})
		})
	}

	const settingsDefault = {
		apiUrl: API_URL,
		url: null,
		method: GET,
		headers: getJSONHeaders(),
		body: null
	};

	if (is.string(configuration)) {
		configuration = {url: configuration};
	} else if (is.array(configuration)) {
		configuration = {url: configuration[0], method: configuration[1], body: configuration[2]}
	}

	let settings = {
		...settingsDefault,
		...configuration
	};

	return new Promise((resolve, reject) => {
		fetch(settings.apiUrl + settings.url, {
			method: settings.method,
			headers: settings.headers,
			body: settings.body,
		}).then((response) => { // On success
			if (response.ok) {
				if(response.constructor === Promise) {
					response.then(data => {
						resolve(data);
					});
				} else
					resolve(response);
			} else {
				let responseClone = response.clone();
				if(responseClone.json) {
					responseClone.json().then((data) => {
						if(data.error == "INVALID_TOKEN") {
							localStorage.removeItem("token");
							window.location.replace("/");
						}
					});
				}

				reject(response);
			}
		}, (response) => { // On error
			if (response.constructor === Promise) {
				response.then(data => {
					reject(data);
				});
			} else
				reject(response);
		})
	});
};

export const formatQueryString = (data) => {
	if (`${data}`.includes("dispatch") || typeof data == "function") {
		return '';
	}
	return data ? `?${data}` : '';
};
