import { CONSOLE_WARN_BOLD, E_APIServiceActions } from "../models/constants/Constants_Shared";
import { T_AddressModel } from "../models/Models_Shared";
import { E_ToastStyle } from "../models/Models_Toast";
import is from './is';
import { getIconFromError } from "../store/ResponseHandling";
import { generalizeResponseContent } from "./localeUtils";
import { EO } from "./extensions";

/**
 * Get
 * ---
 * Returns value from Object[path]
 * @param {Object} object Starting object
 * @param {String|Array} path String/Array path eg. "prop1.prop2.prop.3" or [prop1, prop2, prop3]
 * @param {*} fallback Return value if path is not string/Array or if the target destination cannot be reached
 * @param {Function} processor Process retrieved value
 * @returns {null|*} Returns value if found or {null} otherwise
 */
export const get = (object, path, fallback = null, processor = value => value) => {
	if (!is.valid(object)) { return fallback; }

	let target = object;

	if (is.string(path)) {
		path = path.split(".");
	}

	if (!is.array(path)) {
		return is.valid(target) ? processor(target) : fallback;
	}

	for (let i = 0; i < path.length; i++) {
		let pathSegment = path[i];

		if (pathSegment === "$") {
			if (is.array(target))
				target = target.lastItem();
			else
				return fallback;
		} else if (pathSegment.match(/^#/g)) {
			if (is.array(target))
				target = target.findByID(pathSegment.replace("#", ''));
			else
				return fallback;
		} else {
			if (target && (Object.keys(target).includes(pathSegment) || hasOwnProperty(target, pathSegment)) && target[pathSegment] != null)
				target = target[pathSegment];
			else
				return fallback;
		}
	}
	return processor(target);
};

/**
 * CSS
 * ---
 * Set styles to element
 * @param {Object|Array|String} styles Array of style properties and values
 * @param {Element} target Target
 * @returns {Object|null}
 */
export const css = (styles, target = null) => {
	let result = {};
	if (["object", "string"].includes(typeof styles)) {
		let parsedStyles = is.string(styles) ? styles.split(";") : styles;
		parsedStyles = Array.isArray(parsedStyles) ? parsedStyles : EO(parsedStyles).toArray().map(item => `${item.key}:${item.value}`)
		parsedStyles = parsedStyles.filter(style => is.valid(style));

		for (let i = 0; i < parsedStyles.length; i++) {
			let [styleKey, styleValue] = parsedStyles[i].split(":");
			styleKey = styleKey.trim();

			if (!styleKey || !styleValue) {
				console.log("Style is not complete!", {[styleKey]: styleValue});
				console.log("%cProvided styles", CONSOLE_WARN_BOLD, styles);
				continue;
			}

			//If the key starts with -- it should represent css variable (e.g. --css-variable) thus the key should stay the same
			if(!/^--/.test(styleKey)) {
				// Replace multi word key (e.g. max-width) with react style object compatible value (e.g. 'max-width' => 'maxWidth')
				styleKey = styleKey.replace(/(-.)/g,x => x[1].toUpperCase());
			}
			styleKey = styleKey.trim();

			// Append "px" if the value is a lone number (e.g. 10 => "10px")
			styleValue = parseFloat(styleValue) == styleValue ? `${styleValue.trim()}px` : styleValue.trim(); //TODO: remove; will cause trouble for f.e. variables or opacity etc.

			result[styleKey] = styleValue;
		}
	}

	// If target is invalid, return the styles (useful when passing css as a style prop into a react component. E.g. <div style={css(...)}/>)
	if(!target) {
		return result;
	}

	// Apply styles to the target
	for (let styleKey in result) {
		if (hasOwnProperty(result, styleKey)) {
			target.style[styleKey] = result[styleKey];
		}
	}
};

export const hasParent = (startElement, ...target) => {
	let el = startElement;

	while (el && el.parentElement) {
		if (target.includes(el)) {
			return true;
		}
		el = el.parentElement;
	}
	return false;
};

export const closest = (startElement, selector) => {
	let target = startElement;

	while (target && target.parentElement) {
		if (matchSelector(target, selector)) {
			return target;
		}
		target = target.parentElement;
	}
	return null;
};

export const matchSelector = (element, selector) => {
	if (element && element.parentElement) {
		let elements = [...element.parentElement.querySelectorAll(selector)];
		return elements.find(item => (item === element));
	}
	return false;
};

export class Debouncer {
	constructor(delay) {
		this.timeout = null;
		this.delay = delay;
	}

	call(callback, ...params) {
		if (!this.timeout) {
			callback(...params);
			this.timeout = setTimeout(()=>{
				this.timeout = null;
			}, this.delay);
		}
	}
}

export class ExtendedTimeout {
	constructor(callback, time, reportCallback = () => null, reportInterval = null) {
		this.callback = callback;
		this.time = time;
		this.reportCallback = reportCallback;
		this.reportInterval = reportInterval;

		this.start();
	}

	setupTimeout(timeout) {
		if(timeout > 0) {
			this._triggerTime = new Date().getTime();
			this._timeout = setTimeout(() => this.callback(), timeout);
		} else {
			this.stop();
		}
	}

	setupReportInterval(interval = null) {
		if(interval || this.reportInterval) {
			this._interval = setInterval(() => this.reportCallback(this._computeReport()), interval || this.reportInterval);
		}
	}

	stop() {
		this._clearInterval();
		this._clearTimeout();
		this._remainingTime = 0;
		this._triggerTime = 0;
	}

	pause() {
		this._clearInterval();
		this._clearTimeout();
		this._remainingTime = (this._remainingTime || this.time) - (new Date().getTime() - this._triggerTime);
	}

	start() {
		if(this._timeout == null) {
			this.setupTimeout(this._remainingTime || this.time);
			this.setupReportInterval();
		}
	}

	_computeReport() {
		let {_triggerTime, time, _remainingTime} = this;

		let remaining = Math.max(0, time - new Date().getTime() - _triggerTime);
		let alphaFromTriggerTime = mapRangeUnclamped(new Date().getTime(), _triggerTime, _triggerTime + (_remainingTime || time));
		let alpha = mapRangeUnclamped(
			new Date().getTime(),
			_triggerTime - (time - (_remainingTime || time)),
			_triggerTime + (_remainingTime || time)
		);

		return {
			remaining,
			alphaFromTriggerTime,
			alpha
		};
	}

	_clearInterval() {
		clearInterval(this._interval);
		this._interval = null;
	}

	_clearTimeout() {
		clearTimeout(this._timeout);
		this._timeout = null;
	}
}

/**
 * Map Range Unclamped
 * @param {Number} value Value
 * @param {Number} low Low value
 * @param {Number} high High value
 * @param {Number} lowOut Return low value (default: 0)
 * @param {Number} highOut Return high value (default: 1)
 * @returns {Number}
 */
export const mapRangeUnclamped = (value, low, high, lowOut = 0, highOut= 1) => {
	return lowOut + (highOut - lowOut) * (value - low) / (high - low);
};

export const clamp = (value, min, max) => {
	if (is.valid(min) && is.valid(max)) {
		return Math.max(Math.min(value, max), min);
	} else if (is.valid(min)) {
		return Math.max(value, min);
	}
	return Math.min(value, max);
};

export const isWithinClampRange = (value, min, max) => {
	value = parseFloat(value);
	return value >= min && value <= max;
};

export const $$ = (selector, startElement = document, all = false) => {
	return all && startElement.querySelectorAll(selector) || startElement.querySelector(selector);
};

/**
 * Idle Manager
 * ---
 * Triggers an event after being idle for {idleTime} in ms
 * @param {Number} idleTime Wait time - for how long the manager will wait before triggering the callback
 * @param {Function} callback Callback function
 */
export class IdleManager {
	idleTime = 1000;
	callback = null;
	timeout = null;

	constructor(callback, idleTime) {
		this.idleTime = idleTime;
		this.callback = callback;
	}

	/**
	 * Trigger
	 * ---
	 * Triggers idle timeout refresh
	 */
	trigger() {
		if (this.timeout) {
			this._clearTimeout();
		}

		this._setupTimeout();
	}

	/**
	 * Force Trigger
	 * ---
	 * Ignores and invalidates the idle timeout and calls the callback immediately
	 *
	 * **NOTE!** The timeout will be CLEARED and will NOT start again after the callback.
	 */
	forceTrigger() {
		this._clearTimeout();
		this._trigger();
	}

	/**
	 * Is Active
	 * ---
	 * Returns if the timeout is active
	 * @returns {boolean} Is active
	 */
	isActive() {
		return !!this.timeout;
	}

	/**
	 * @private
	 * Clear Timeout
	 * ---
	 */
	_clearTimeout() {
		clearTimeout(this.timeout);
		this.timeout = null;
	}

	/**
	 * @private
	 * Setup Timeout
	 * ---
	 */
	_setupTimeout() {
		this.timeout = setTimeout(this._trigger.bind(this), this.idleTime);
	}

	/**
	 * @private
	 * Trigger
	 * ---
	 * Triggers the callback function if is defined or throws an error
	 */
	_trigger() {
		if(is.function(this.callback)) {
			this.callback();
		}
	}
}

/**
 * Parse Boolean
 * ---
 * Parse boolean from string if possible
 * @param {String} string Possible string
 * @returns {null|boolean}
 */
export const parseBool = (string) => {
	if (is.string(string)) {
		if (string.toLowerCase().match(/(false)|(disabled)/)) {
			return false;
		}
		if (string.toLowerCase().match(/(true)|(enabled)/)) {
			return true;
		}
	} else if(is.boolean(string)) {
		return string;
	}

	return null;
};

/**
 * Call Error Toast
 * ---
 * Call an error styled toast
 * @param {*} content
 * @param {T_ToastModel} props
 */
export const callErrorToast = (content, props) => {
	window.toaster.showToast({
		content: content,
		style: E_ToastStyle.ERROR,
		...props
	});
};

/**
 * Valid
 * ---
 * Returns [value] or [fallback] based on is.valid() function or exceptions if any
 * @param {*} value Provided value that is going to be validated
 * @param {*} fallback Validation failure value
 * @param {Array} exceptions Validation exceptions that are meant to be ignored
 * @returns {*}
 */
export const valid = (value, fallback, exceptions = []) => {
	return exceptions.includes(value) ? value : (is.valid(value) ? value : fallback);
};

export function formatAsPrice(value, currency = "CZK", instigatorOrLocale) {
	let locale = is.string(instigatorOrLocale) ? instigatorOrLocale : get(
		instigatorOrLocale,
		`props.language.currentLanguage.languageCode`,
		"cs"
	);
	return new Intl.NumberFormat(locale, currency && {style: "currency", currency: currency} || undefined).format(value);
}

export function formatAsDate(date, options = undefined) {
	return date && new Date(date).toLocaleString(window.translator.getActiveLanguage(true), options);
}

export const copyToClipboard = text => {
	if (navigator.clipboard && navigator.clipboard.writeText) {
		navigator.clipboard.writeText(`${text}`).then(() => {
			window.toaster.showToast({
				content: window.translator.translate("clipboard_copy_success"),
				style: E_ToastStyle.SUCCESS,
				icon: "clipboard-check"
			});
		}, () => {
			window.toaster.showToast({
				content: window.translator.translate("clipboard_copy_error"),
				style: E_ToastStyle.ERROR,
				icon: "clipboard"
			});
		});
	} else {
		window.toaster.showToast({
			content: window.translator.translate("clipboard_copy_notAvailable"),
			style: E_ToastStyle.ERROR,
			icon: "clipboard"
		});
	}
};

/**
 * Format address in one line
 * ---
 * @param {T_AddressModel} address
 * @returns {string}
 */
export const formatAddressInOneLine = address => {
	address = {...T_AddressModel, ...address};
	let result = [];

	address.street && result.push(address.street);
	address.houseNumber && result.push(address.houseNumber);
	address.city && result.push(address.city);
	address.country && result.push(address.country);

	return result.join(" ");
};

/**
 * Resolve Dynamic Component
 * ---
 * Resolves passed value according to predefined conditions
 * ```
 *  let value = "abc"
 *  resolveDynamicComponent(value, <div>{value}</div>) => <div>abc</div>
 *
 *  let value = (r) => "a" + r
 *  resolveDynamicComponent(value, <div>{value}</div>, "bc") => <div>abc</div>
 *
 *
 *  let value = <a>efg</a>
 *  resolveDynamicComponent(value, <div>{value}</div>) => <a>efg</a>
 *
 *  //Function params are ignored if {value} is not a function
 *  resolveDynamicComponent(value, <div>{value}</div>, "bc", true, 5, new Date()) => <a>efg</a>
 * ```
 * @param value
 * @param fallback
 * @param functionProps
 * @returns {*}
 */
export const resolveDynamicComponent = (value, fallback, ...functionProps) => {
	if (!is.valid(value)) {return;}

	if (is.string(value)) { //Value is string
		return fallback
	} else if (is.function(value)) { //Value is function
		return value(...functionProps);
	}
	return value; //Value is something else (most probably React component)
};

/**
 * Resolve item name
 * ---
 * Resolves item name when only the main object is provided along with a path to the main and alternative result which handles the missing value
 * ```
 *  let data = {name: "abc"}
 *  //Main path value is valid
 *  resolveItemName(data, `name`) => "abc"
 *
 *  data = {id: 2}
 *  //Main path value is invalid so an alternative path will be used
 *  resolveItemName(data, `name`, `id`) => 2
 *
 *  data = {name: "abc", id: 2}
 *  //Main path is valid so there is no need for an alternative value
 *  resolveItemName(data, `name`, `id`) => "abc"
 *
 *  data = {}
 *  //Neither main or alternative path returns valid value so the fallback will be used
 *  resolveItemName(data, `name`, `id`, "fallback value") => "fallback value"
 *
 *  data = {partner: {id: 2, name: "test"}}
 *  //Main path value is valid so the processor can apply any additional modification
 *  resolveItemName(data, `name`, `id`, '', v => v + " value") => "test value"
 *
 *  data = {partner: {id: 2}}
 *  //Main path value is invalid but the alternative is valid so the processor and also the alternative one can apply any additional modification
 *  resolveItemName(data, `name`, `id`, '', v => v + " value", v => "alternative " + v) => "alternative 2 value"
 * ```
 * @param {*} base
 * @param {String|Array} path
 * @param {String|Array} altPath
 * @param {*} fallback
 * @param {Function} processor
 * @param {Function} altProcessor
 * @returns {*}
 * @see get
 */
export const resolveItemName = (base, path = `name`, altPath = `id`, fallback, processor = a => a, altProcessor = a => `#${a}`) => {
	if(is.valid(get(base, path))) {
		return get(base, path, fallback, processor);
	}

	let alt = get(base, altPath, fallback, altProcessor);
	if(alt) {
		return processor(get(base, altPath, fallback, altProcessor));
	}
	return fallback;
};

export const sortBySortOrder = (a,b) => {
	if(a && b) {
		return a.sortOrder - b.sortOrder;
	}
	return 0;
}

export const sortByID = (a,b) => {
	if(a && b) {
		return a.id - b.id;
	}
	return 0;
}

/**
 * Format bytes
 * ---
 * Returns formatted bytes in the closest format e.g. 5_000B => 5kB or 1_000_000B => 1mB
 * @param bytes
 * @param decimals
 * @returns {string}
 */
export const formatBytes = (bytes, decimals = 2) => {
	if (bytes === 0) return '0 Bytes';

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const resolveErrorBlockContentByError = (error, defaultErrorContent = null, action = E_APIServiceActions.ITEM_FETCH) => {
	return {
		icon: getIconFromError(error),
		title: generalizeResponseContent(defaultErrorContent, error, null, action),
		message: error != "TypeError: Failed to fetch" && generalizeResponseContent('', error, null, null, true) || '',
	}
}

export const resolveChildren = (children, wrapInArray = false, ...functionParams) => {
	switch (typeof children) {
		case "object":
			if(Array.isArray(children)) {
				return children;
			} else {
				return wrapInArray ? [children] : children;
			}
		case "function":
			return children(...functionParams);
		default:
			return children || null;
	}
}

export const getModifiedState = (state, type, path, value) => {
	let newState = EO(state).clone(true);
	newState.modify(type, path, value);
	return newState;
}

export const computeDropdownContentPosition = (triggerRef, contentRef) => {
	if(triggerRef && contentRef) {
		let triggerRect = triggerRef.getBoundingClientRect();
		let contentRect = contentRef.getBoundingClientRect();
		let {innerHeight, innerWidth} = window;
		let availableSpaceOnBottom = innerHeight - triggerRect.bottom;
		let availableSpaceOnTop = innerHeight - (innerHeight - triggerRect.top);

		let contentStyle = {
			top: triggerRect.bottom,
			left: triggerRect.left,
			minWidth: triggerRect.width,
			maxWidth: innerWidth - triggerRect.left,
		};

		if(contentStyle.top + contentRect.height > innerHeight) {
			if(availableSpaceOnTop > availableSpaceOnBottom) {
				//Preferred align is top
				contentStyle = {
					...contentStyle,
					top: null,
					bottom: innerHeight - triggerRect.top,
					maxHeight: availableSpaceOnTop,
				}
			}
			else {
				contentStyle = {
					...contentStyle,
					maxHeight: availableSpaceOnBottom,
				}
			}
		}

		return contentStyle;
	}
	return {};
}

export const combineClasses = (...params) => {
	return params.filter(param => !!param && `${param}`.trim()).join(" ");
};

export const hasOwnProperty = (o, ...properties) => {
	return properties ? properties.every(prop => Object.prototype.hasOwnProperty.call(o, prop)) : false;
}
