// eslint-disable-next-line no-unused-vars
import { DELETE, formatQueryString, POST, PUT, sendRequest } from "./apiServices/apiConnector";
import { Process_formatQueryString } from "../utils/processors";
import { E_OpenBoxModes } from "../models/Models_Session";

export const service_fetchSessions = (queryString = null) => {
	return sendRequest(`/session/${formatQueryString(queryString)}`).then(response => response.json());
};

export const service_fetchSession = (sessionID) => {
	return sendRequest(`/session/${sessionID}`).then(response => response.json());
};

export const service_insertSession = (body) => {
	return sendRequest([`/session/`, POST, body]).then(response => response.json());
};

export const service_updateSession = (sessionID, body) => {
	return sendRequest([`/session/${sessionID}`, PUT, body]).then(response => response.json());
};

export const service_cancelSession = (sessionID) => {
	return sendRequest([`/session/${sessionID}`, DELETE]).then(response => response);
};

export const service_completeSession = (sessionID, openMode, force = false) => {
	return sendRequest([`/session/${sessionID}/complete${formatQueryString(Process_formatQueryString({
		openMode,
		force,
	}))}`, POST]).then(response => response.json());
};

export const service_fetchSessionTransactions = (sessionID) => {
	return sendRequest(`/session/${sessionID}/transaction/`).then(response => response.json());
};

export const service_openSessionServiceBlock = (sessionID) => {
	return sendRequest([`/session/${sessionID}/open`, POST]).then(response => response.json());
};

export const service_createNewDelivery = (body) => {
	return sendRequest([`/session/delivery/v2`, POST, body]).then(response => response.json());
};

export const service_fetchSessionFromPin = (pin) => {
	return sendRequest(`/session/pin${formatQueryString(Process_formatQueryString(pin))}`).then(response => response.json());
};

export const service_openBoxFromPin = (pin, openMode = E_OpenBoxModes.OPEN_NOW) => {
	return sendRequest([`/session/pin/open${formatQueryString(Process_formatQueryString({pin, openMode}))}`, POST]).then(response => response.json());
};

export const service_completeDeliverySessionFromPin = (pin, openMode = E_OpenBoxModes.DO_NOT_OPEN) => {
	return sendRequest([`/session/delivery/pin/complete${formatQueryString(Process_formatQueryString({pin, openMode}))}`, POST]).then(response => response.json());
};

export const service_completeSessionFromPin = (pin, openMode = E_OpenBoxModes.DO_NOT_OPEN) => {
	return sendRequest([`/session/pin/complete${formatQueryString(Process_formatQueryString({pin, openMode}))}`, POST]).then(response => response.json());
};

/**
 * Service get session cost
 * ---
 * @param {{
 *  serviceID: Number,
 *  timeBracketID?: Number,
 *  voucherCode?: String,
 *  email?: String,
 *  phone?: String,
 *  locale?: String,
 *  userID?: Number,
 *  activateNow?: Boolean,
 *  notes?: String,
 *  customID?: String,
 *  reservationFrom: Date,
 *  reservationTo: Date
 * }} body
 * @return {Promise}
 */
export const service_getSessionCost = (body) => {
	return sendRequest([`/session/cost`, POST, JSON.stringify(body)]).then(response => response.json());
};
