// eslint-disable-next-line no-unused-vars
import { DELETE, formatQueryString, POST, PUT, sendRequest } from "./apiServices/apiConnector";
import { Process_formatQueryString } from "../utils/processors";

export const service_fetchTariffs = (queryString = null) => {
	return sendRequest(`/tariff/${formatQueryString(queryString)}`).then(response => response.json());
};

export const service_fetchTariff = (tariffID) => {
	return sendRequest(`/tariff/${tariffID}`).then(response => response.json());
};

export const service_insertTariff = (body) => {
	return sendRequest([`/tariff/`, POST, body]).then(response => response.json());
};

export const service_updateTariff = (tariffID, body) => {
	return sendRequest([`/tariff/${tariffID}`, PUT, body]).then(response => response.json());
};

export const service_deleteTariff = (data) => {
	const {id, replacementID} = data || {};
	return sendRequest([`/tariff/${id}${formatQueryString(Process_formatQueryString({replacementID}))}`, DELETE]).then(response => response);
};
