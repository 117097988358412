import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from "react-localize-redux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { T_APP_ICONS } from "../../../models/constants/Constants_Shared";
import { Selector_Users_Listing_Root } from "../../../store/selectors/Selectors_Users";
import { Thunk_Users_Fetch_All } from "../../../store/thunk/Thunk_Users";
import { get, resolveItemName } from "../../../utils/utils";
import CSBaseComponent from "../../CSBaseComponent";
import { withRouter } from 'react-router-dom';
import routes from '../../../routes/routes';
import ListingPage from "../../shared/Listing/ListingPage";
import TableListing from "../../shared/Listing/TableListing";
import { T_SortType } from "../../../models/Models_Shared";
import TableRow from "../../shared/Listing/TableRow";
import TableColumn from "../../shared/Listing/TableColumn";
import ItemRedirect from "../../shared/ItemRedirect";
import { T_RestrictionPresets } from "../../../models/Models_Restrictions";

class UserList extends CSBaseComponent {
	_renderTemplate(user, i, headerItems) {
		return (
			<TableRow key={i} href={`${routes.USERS}/${user.id}`} headerItems={headerItems}>
				<TableColumn column={"email"}>{user.email}</TableColumn>
				<TableColumn column={"fullName"}>{user.fullName || '-'}</TableColumn>
				<TableColumn column={"role"}>{get(user, `permissions.role`)}</TableColumn>
				<TableColumn column={"partner"}>
					{
						user.partner &&
						<ItemRedirect href={`${routes.PARTNERS}/${user.partner.id}`}>
							{resolveItemName(user.partner)}
						</ItemRedirect>
						||
						'-'
					}
				</TableColumn>
			</TableRow>
		);
	}

	render() {
		const {Thunk_Users_Fetch_All} = this.props;

		return (
			<section className={"users-list"}>
				<ListingPage
					section={"users"}
					titleIcon={T_APP_ICONS.USERS}
					titleText={"users"}
					titleLeftContent={routes.USERS}
					subscriberThunk={Thunk_Users_Fetch_All}
					subscriberRootSelector={Selector_Users_Listing_Root}
					headerItems={{
						email: {
							sort: T_SortType.TEXT
						},
						fullName: {
							label: "user_name",
							filter: false,
							sort: false,
						},
						role: {
							label: "user_role",
							filter: false,
							sort: false,
						},
						partner: {
							field: "permissions.partnerID",
							restricted: T_RestrictionPresets.PARTNERS,
						},
					}}
					views={[
						{
							view: "table",
							default: true,
							button: {
								icon: "table",
								tippy: <Translate id={"viewMode_table"} />
							},
							content: <TableListing className={"stripes"} itemTemplate={this._renderTemplate.bind(this)} />
						},
					]}
				/>
			</section>
		);
	}

	static get propTypes() {
		return {
			Thunk_Users_Fetch_All: PropTypes.func.isRequired,
		}
	}
}

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		Thunk_Users_Fetch_All,
	}, dispatch)
);

export default connect(null, mapDispatchToProps)(withRouter(UserList));
