import Tippy from "../Tippy";
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { NavLink, withRouter } from "react-router-dom";
import { T_APP_ICONS, WINDOW_MIN_WIDTH } from "../../../models/constants/Constants_Shared";
import { T_SharedPropTypes, T_SharedRouterPropTypes, T_SharedTippyProps } from "../../../models/Models_Shared";
import is from "../../../utils/is";
import { resolveDynamicComponent } from "../../../utils/utils";
import CSBaseComponent from "../../CSBaseComponent";
import Fa from "../../tools/Icons/Fa";
import Subscriber from "../../tools/Subscriber";
import TextInput from "../Input/TextInput";
import ViewSwitch from "../ViewSwitch";
import "../../../styles/components/ListingPage.scss";
import { Translate } from "react-localize-redux";
import { EO } from "../../../utils/extensions";
import Restricted from "../Restrictions/Restricted";
import { T_RestrictionPresets } from "../../../models/Models_Restrictions";

class ListingPage extends CSBaseComponent {
	constructor(props) {
		super(props);

		this.state = {
			...super.state,
			search: '',
			searchVisible: props.canSearch && window.innerWidth > WINDOW_MIN_WIDTH, //Visibility condition for the search bar
			filtersEnabled: false //Visual
		};

		this._handleResize = this._handleResize.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
		const {canSearch} = this.props;

		if (canSearch) { //Warning! If this will change at runtime, the resize event will need to be initialized in componentDidUpdate()!
			window.addEventListener("resize", this._handleResize);
			window.addEventListener("popstate", this._handlePopState);
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const {canSearch} = this.props;

		if(!is.equal(prevProps.canSearch, canSearch)) {
			if(canSearch) {
				window.addEventListener("resize", this._handleResize);
			} else {
				window.removeEventListener("resize", this._handleResize);
			}
		}
	}

	componentWillUnmount() {
		super.componentWillUnmount();

		window.removeEventListener("resize", this._handleResize);
		window.removeEventListener("popstate", this._handlePopState);
	}

	_renderSearchBar() {
		const {search} = this.state;
		return (
			<Translate>
				{({translate}) => (
					<TextInput
						onModify={(type, path, search) => this.setState({search})}
						prefix={<Fa icon={"search"} />}
						debounceDelay={2000}
						defaultValue={search}
						canInvalidate={true}
						placeholder={translate("search")}
					/>
				)}
			</Translate>
		)
	}

	_renderHeader() {
		const {title, titleIcon, titleLeftContent, titleText, subscriberFilters, header, section} = this.props;
		const {filtersEnabled, searchVisible} = this.state;
		let visibleFilters = EO(subscriberFilters).filter(item => !item.value.hidden);

		return (
			header
			||
			<div className={"separator title"}>
				<div>
					<Restricted restricted={T_RestrictionPresets.EDIT(section)}>{
						resolveDynamicComponent(
							titleLeftContent,
							<NavLink to={`${titleLeftContent}/0`} className={`list-item add`}>
								<Fa icon={T_APP_ICONS.ADD} />
								<label><Translate id={"add"} /></label>
							</NavLink>
						)
					}</Restricted>
				</div>
				<label>
					{
						title
						||
						<Fragment>
							{resolveDynamicComponent(titleIcon, <Fa icon={titleIcon} />)}
							{resolveDynamicComponent(titleText, <Translate id={titleText}/>)}
						</Fragment>
					}
				</label>
				<div>
					{
						searchVisible &&
						this._renderSearchBar()
					}
					{
						is.valid(visibleFilters) &&
						<Tippy content={<Translate id={"filters"}/>} {...T_SharedTippyProps}>
							<button className={`list-item filter`} active={`${filtersEnabled}`} onClick={() => this.setState({filtersOpen: true})}>
								<Fa icon={T_APP_ICONS.FILTER} />
							</button>
						</Tippy>
					}
				</div>
			</div>
		)
	}

	_renderListing() {
		const {
			title,
			titleIcon,
			titleLeftContent,
			subscriberThunk,
			subscriberRootSelector,
			subscriberDataSelector,
			subscriberDataPath,
			subscriberFilters,
			subscriberFooter,
			subscriberSort,
			views,
			titleText,
			viewClassName,
			header,
			subscriberClassName,
			headerItems,
			mergeSearch,
			match,
			history,
			queryNotify,
			subscriberService,
			subscriberStyle,
			canSearch,
			name,
			subscriberInlineSpinner,
		} = this.props;
		const {search, searchVisible, filtersOpen} = this.state;

		if (is.valid(headerItems) && is.array(views)) {
			(views || []).forEach(view => {
				view.content = React.cloneElement(view.content, {
					headerItems
				});
			})
		}

		return (
			<Fragment>
				{(title || (titleIcon && titleText) || titleLeftContent || header) && this._renderHeader()}

				{
					this._wrapWithDiv(
						<Subscriber
							thunk={() => subscriberThunk}
							rootSelector={subscriberRootSelector}
							dataSelector={subscriberDataSelector}
							dataPath={subscriberDataPath}
							service={subscriberService}
							search={search}
							mergeSearch={mergeSearch}
							filters={subscriberFilters}
							filtersOpen={filtersOpen}
							onQueryParse={query => {
								this.setState({search: query.search});
							}}
							onFiltersApply={filters => {
								this.setState({filtersEnabled: filters.length > 0})
							}}
							onFiltersClosed={() => this.setState({filtersOpen: false})}
							headerItems={headerItems}
							footer={subscriberFooter}
							match={match}
							history={history}
							queryNotify={queryNotify}
							sort={subscriberSort}
							inlineSpinner={subscriberInlineSpinner}
						>
							{
								is.array(views) &&
								<ViewSwitch
									name={name}
									className={viewClassName}
									options={views}
									propagateFailed={false}
									header={
										!searchVisible && canSearch &&
										this._renderSearchBar()
									}
								/>
								||
								React.cloneElement(views, {headerItems})
							}
						</Subscriber>,
						subscriberClassName,
						subscriberStyle
					)
				}
			</Fragment>
		)
	}

	_wrapWithDiv(content, className, style) {
		if (className || style) {
			return <div className={`${className}`} style={style}>{content}</div>
		}
		return content;
	}

	render() {
		const {className, style} = this.props;

		return this._wrapWithDiv(this._renderListing(), className && `listing-page ${className}`, style);
	}

	_handleResize() {
		if (window.innerWidth < WINDOW_MIN_WIDTH && this.state.searchVisible) {
			this.setState({searchVisible: false});
		}

		if (window.innerWidth > WINDOW_MIN_WIDTH && !this.state.searchVisible) {
			this.setState({searchVisible: true});
		}
	}
}

ListingPage.propTypes = {
	...T_SharedPropTypes,
	...T_SharedRouterPropTypes,
	header: PropTypes.any,
	title: PropTypes.any,
	titleText: PropTypes.any,
	titleIcon: PropTypes.any,
	titleLeftContent: PropTypes.any,
	canSearch: PropTypes.bool,
	subscriberThunk: PropTypes.func,
	subscriberRootSelector: PropTypes.func,
	subscriberDataSelector: PropTypes.func,
	subscriberDataPath: PropTypes.string, // Path for data array (used if subscriberDataSelector is not defined);
	subscriberService: PropTypes.any, //Promise
	subscriberFilters: PropTypes.object.isRequired,
	subscriberFooter: PropTypes.any,
	subscriberClassName: PropTypes.string,
	subscriberStyle: PropTypes.object,
	subscriberSort: PropTypes.string,
	subscriberInlineSpinner: PropTypes.bool,
	views: PropTypes.any.isRequired,
	viewClassName: PropTypes.string,
	/**
	 * @type {Object<String, T_ListingHeaderItem>|Array<String>}
	 */
	headerItems: PropTypes.object,
	mergeSearch: PropTypes.bool,
	queryNotify: PropTypes.bool,
	name: PropTypes.string, //If defined viewSwitch data (and it's children) will be saved to localStorage
	section: PropTypes.string, //Permission section; If defined, will look for permission section inside the principal.permissions
};

ListingPage.stateTypes = {
	search: PropTypes.string,
	searchVisible: PropTypes.bool,
	filtersEnabled: PropTypes.bool,
	filtersOpen: PropTypes.bool,
};

ListingPage.defaultProps = {
	canSearch: true,
	subscriberFooter: true,
	subscriberFilters: {},
	views: [],
	queryNotify: true,
};

export default withRouter(ListingPage);
