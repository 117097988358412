// eslint-disable-next-line no-unused-vars
import { DELETE, formatQueryString, POST, PUT, sendRequest } from "./apiServices/apiConnector";

export const service_fetchLocations = (queryString = null) => {
	return sendRequest(`/location/${formatQueryString(queryString)}`).then(response => response.json());
};

export const service_fetchLocation = (locationID) => {
	return sendRequest(`/location/${locationID}`).then(response => response.json());
};

export const service_insertLocation = (body) => {
	return sendRequest([`/location/`, POST, body]).then(response => response.json());
};

export const service_updateLocation = (locationID, body) => {
	return sendRequest([`/location/${locationID}`, PUT, body]).then(response => response.json());
};

export const service_deleteLocation = (locationID) => {
	return sendRequest([`/location/${locationID}`, DELETE]).then(response => response)
};

/*  === === === === === ===
			 Unit
    === === === === === === */

export const service_fetchUnits = (locationID) => {
	return sendRequest(`/location/${locationID}/unit/`).then(response => response.json());
};

export const service_fetchUnit = (unitID) => {
	return sendRequest(`/unit/${unitID}`).then(response => response.json());
};

export const service_insertUnit = (locationID, body) => {
	return sendRequest([`/location/${locationID}/unit/`, POST, body]).then(response => response.json());
};

export const service_updateUnit = (unitID, body) => {
	return sendRequest([`/unit/${unitID}`, PUT, body]).then(response => response.json());
};

export const service_deleteUnit = (unitID) => {
	return sendRequest([`/unit/${unitID}`, DELETE]).then(response => response);
};

/*  === === === === === ===
			Frame
    === === === === === === */

export const service_fetchFrames = (unitID) => {
	return sendRequest(`/unit/${unitID}/frame/`).then(response => response.json());
};

export const service_fetchFrame = (frameID) => {
	return sendRequest(`/frame/${frameID}`).then(response => response.json());
};

export const service_insertFrame = (unitID, body) => {
	return sendRequest([`/unit/${unitID}/frame/`, POST, body]).then(response => response.json());
};

export const service_updateFrame = (frameID, body) => {
	return sendRequest([`/frame/${frameID}`, PUT, body]).then(response => response.json());
};

export const service_deleteFrame = (frameID) => {
	return sendRequest([`/frame/${frameID}`, DELETE]).then(response => response);
};

/*  === === === === === ===
             Block
    === === === === === === */

export const service_fetchBlocks = (frameID) => {
	return sendRequest(`/frame/${frameID}/block/`).then(response => response.json());
};

export const service_fetchBlock = (blockID) => {
	return sendRequest(`/block/${blockID}`).then(response => response.json());
};

export const service_insertBlock = (frameID, body) => {
	return sendRequest([`/frame/${frameID}/block/`, POST, body]).then(response => response.json());
};

export const service_updateBlock = (blockID, body) => {
	return sendRequest([`/block/${blockID}`, PUT, body]).then(response => response.json());
};

export const service_deleteBlock = (blockID) => {
	return sendRequest([`/block/${blockID}`, DELETE]).then(response => response);
};
